.mobile-app {
  position: relative;
  .iphone {
    position: relative;

    video {
      object-fit: cover;
      width: 21.3375rem;
      z-index: 0;
      height: 45.8125rem;
      margin-top: 20px;

      @media screen and(max-width: 375px) {
        width: 19.4375rem;
        height: 41.9rem;
        margin-top: 14px;
      }
    }

    img {
      width: 32.5rem;
      height: 50rem;
      position: absolute;
      bottom: auto;
      text-align: center;
      z-index: 1;

      @media screen and(max-width: 375px) {
        width: 30.5rem;
        height: 45rem;
      }
    }
  }

  .android {
    position: relative;

    video {
      object-fit: cover;
      width: 24.9375rem;
      z-index: 0;
      height: 51.8125rem;
      margin-top: 16px;
      border-radius: 10px;

      @media screen and(max-width: 375px) {
        width: 20.5rem;
        height: 41.9rem;
        margin-top: 14px;
      }
    }

    img {
      width: 25.5rem;
      height: 54rem;
      position: absolute;
      bottom: auto;
      text-align: center;
      z-index: 1;

      @media screen and(max-width: 375px) {
        width: 21.5rem;
        height: 44rem;
      }
    }
  }
}

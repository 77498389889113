.subscribe {
  .credit-card {
    h2 {
      font-size: 1.5rem;
    }

    .total-amount {
      font-size: 1.5rem;
    }
  }
}

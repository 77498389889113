.plans {
  h2 {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.75rem;
  }

  .desc {
    font-weight: bold;
    color: #737373;
    font-size: 0.8rem;
  }

  .price {
    font-weight: bold;
    color: #737373;

    .value {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1rem;
    }
  }

  .input-range {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .input-range__track {
    height: 1.2rem;
  }

  .input-range__track--active {
    background: linear-gradient(to right, #56a849, #79e469);
  }

  .input-range__slider {
    background: #7dd56f;
    border: 1px solid #7dd56f;
    width: 2rem;
    height: 2rem;
    margin-top: -1.6rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .input-range__label--value {
    top: -4rem;
    left: 0.625rem;

    .input-range__label-container:nth-child(1n) {
      background-color: #7dd56f;
      padding: 0.4375rem 1.5625rem;
      box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      font-weight: bold;
      font-family: Open Sans, sans-serif;
      font-size: 0.8rem;
      line-height: 1rem;
      text-align: center;
      color: #ffffff;
    }
  }

  .input-range__label--min {
    .input-range__label-container {
      display: none;
    }
  }

  .input-range__label--max {
    .input-range__label-container {
      display: none;
    }
  }
}

form .cep {
  width: 120px;
}

form .cpf {
  width: 200px;
}

form .address-number {
  width: 100px;
}

.next-features {
  .progress-height {
    height: 80px;
  }

  .scrollable {
    max-height: 507px;
    overflow-y: scroll;
  }

  .scrollable::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  .scrollable::-webkit-scrollbar {
    width: 8.4px;
    background-color: #ffffff;
  }

  .scrollable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #7dd56f;
    background-image: -webkit-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
  }
}

.subscribe {
  .subscribe-width {
    @media (min-width: 1300px) {
      width: 70% !important;
    }
  }

  .title {
    font-size: 1.9rem;
    line-height: 2.5rem;
    color: #6c6d87;
  }
}

.table-products {
  width: 80% !important;
}

.product-cost-changed {
  &::placeholder {
    color: #28cf87 !important;
    font-weight: bold;
  }
}

.input-select__icon svg {
  max-width: 20px;
  max-height: 20px;
  padding-right: 4px;
}
